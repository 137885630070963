import Swiper from 'swiper';
import { assign } from 'lodash';
import { nodeListToArray } from '../helpers/nodeListToArray';
import * as YouTubeIframeLoader from 'youtube-iframe';
import { Player } from "@vimeo/player";

export interface IGallery {
    containerClass: string;
    slidesPerView?: number;
    breakpoints?: any;
    spaceBetween?: number;
    navigation?: any;
    autoHeight?: boolean;
    loop?: boolean;
    autoplayVideo?: boolean;
    pageNumberPreposition?: string;
}

export class Gallery {
    //these params are exposed to the file you intialize your gallery in, so you can initialize multiple
    //gallery versions across your project or just be able to configure without diving into this file
    params: IGallery = {
        containerClass: 'adage-gallery-js',
        slidesPerView: 1,
        breakpoints: {
            640: {
                spaceBetween: 16
            }
        },
        spaceBetween: 40,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        autoHeight: false,
        loop: true,
        autoplayVideo: false,
        pageNumberPreposition: ' of '
    };
    gallery: any;
    activeSlide: any;
    previousSlide: any;
    activeIndex: any;
    previousIndex: number; // swiper's previousIndex implementation doesn't work with loop: true so we need to do it manually
    videos: any;
    galleryId: string;

    constructor(params?: IGallery) {

        assign(this.params, params);

        this.gallery = new Swiper(`.${this.params.containerClass}`, {
            // feel free to add any other Swiper settings if needed
            slidesPerView: this.params.slidesPerView,
            breakpoints: this.params.breakpoints,
            spaceBetween: this.params.spaceBetween,
            navigation: this.params.navigation,
            autoHeight: this.params.autoHeight,
            loop: this.params.loop,
            loopAdditionalSlides: this.params.slidesPerView + 1,
            on: {
                slideChangeTransitionStart: this.handleSlide.bind(this)
            },
            a11y: true
        });
        this.galleryId = this.gallery.el.getAttribute('data-unique-id');
        this.videos = {};
        this.initCallback();
    }

    initCallback() {
        this.activeSlide = this.gallery.slides[this.gallery.activeIndex];
        this.activeIndex = this.gallery.activeIndex;
        this.setPageNumbers();
        this.initVisibleVideos();
    }

    setPageNumbers() {
        if (this.gallery) {
            let totalSlides = this.gallery.el.getAttribute('data-total-slides');
            for (let i = 0; i < this.gallery.slides.length; i++) {
                let slide = this.gallery.slides[i];
                this.reconcileDupeId(slide);
                if (i !== this.activeIndex) {
                    this.disableSlide(slide);
                }
                let slidePageContainer = slide.querySelector('[data-gallery-page]');
                if (slidePageContainer) {
                    let pageString = `${slide.getAttribute('data-slide-index')}${this.params.pageNumberPreposition}${totalSlides}`;
                    slidePageContainer.innerHTML = pageString;
                }
            }

        }
    }

    reconcileDupeId(slide) {
        // check to see if there's 2 slides with same unique ID (when loop is true, slides get duplicated)
        if (slide.querySelector('[data-unique-id]')) {
            let thisId = slide.querySelector('[data-unique-id]').getAttribute('data-unique-id');
            if (this.gallery.el.querySelectorAll(`[data-unique-id="${thisId}"]`).length > 1) {
                let dupes = Array.prototype.slice.call(this.gallery.el.querySelectorAll(`[data-unique-id="${thisId}"]`));
                dupes.forEach((dupe, index) => {
                    dupe.setAttribute('data-unique-id', `${thisId}-${index}`);
                })
            }
        }
    }

    handleSlide() {
        if (this.gallery) {
            this.previousSlide = this.activeSlide;
            this.previousIndex = this.activeIndex;
            this.activeSlide = this.gallery.slides[this.gallery.activeIndex];
            this.activeIndex = this.gallery.activeIndex;
            this.initVisibleVideos();
            this.pauseInactiveVideos();
            this.disableSlide(this.previousSlide);
            this.enableSlide(this.activeSlide);
        }
    }

    initVisibleVideos() {
        let activeSlideVideo = this.activeSlide.querySelector('[data-video-type]');
        if (activeSlideVideo) {
            let videoType = activeSlideVideo.getAttribute('data-video-type');
            if (videoType == 'vimeo') {
                this.vimeoInit();
            }
            else if (videoType == 'youtube') {
                this.youtubeInit();
            }
            else {
                this.nativeVideoInit();
            }
        }
    }

    pauseInactiveVideos() {
        let prevSlideVideo = this.previousSlide.querySelector('[data-video-type]');
        if (prevSlideVideo) {
            this.pauseVid();
        }
    }

    vimeoInit() {
        let vimeoIframe = this.activeSlide.querySelector('iframe');
        if (!vimeoIframe.src) {
            vimeoIframe.src = vimeoIframe.getAttribute('data-src');
            let vimeoPlayer = new Player(vimeoIframe, {});
            vimeoPlayer.on('loaded', this.playVid.bind(this));
            this.videos[this.gallery.activeIndex] = vimeoPlayer;
        }
        else {
            this.playVid();
        }
    }

    youtubeInit() {
        let youtubeIframe = this.activeSlide.querySelector('iframe');
        if (!youtubeIframe.src) {
            YouTubeIframeLoader.load(YT => {
                let playerID = `youtube-player-${this.galleryId}-${this.gallery.activeIndex}`;
                youtubeIframe.id = playerID;
                youtubeIframe.src = youtubeIframe.getAttribute('data-src');
                let youtubePlayer = new YT.Player(playerID, {
                    events: {
                        'onReady': this.playVid.bind(this)
                    }
                });
                this.videos[this.gallery.activeIndex] = youtubePlayer;
            });
        }
        else {
            this.playVid();
        }
    }

    nativeVideoInit() {
        let videoElement = this.activeSlide.querySelector('video');
        if (videoElement) {
            let videoSource = videoElement.querySelector('source');
            if (!videoSource.src) {
                videoSource.src = videoSource.getAttribute('data-src');
                videoElement.load();
                videoElement.addEventListener('loadeddata', () => {
                    this.playVid.bind(this);
                })
                this.videos[this.gallery.activeIndex] = videoElement;
            }
        }
    }

    playVid() {
        let player = this.videos[this.gallery.activeIndex];
        let videoContainer = this.activeSlide.querySelector('[data-video-type]');
        if (this.params.autoplayVideo && this.previousSlide) {
            if (videoContainer.getAttribute('data-video-type') == 'vimeo') {
                player.play();
            }
            else if (videoContainer.getAttribute('data-video-type') == 'youtube') {
                player.playVideo();
            }
            else {
                player.play();
            }
        }
    }

    pauseVid() {
        let player = this.videos[this.previousIndex];
        let videoContainer = this.previousSlide.querySelector('[data-video-type]');
        if (videoContainer.getAttribute('data-video-type') == 'vimeo') {
            player.pause();
        }
        else if (videoContainer.getAttribute('data-video-type') == 'youtube') {
            player.pauseVideo();
        }
        else {
            if (!player.paused) {
                player.pause();
            }
        }
    }

    disableSlide(slide) {
        // disable tabbing on tabbable content by default to stop trapping keyboard, and hide from screenreader users
        let tabbableContent = Array.prototype.slice.call(slide.querySelectorAll('a, input, button, area, object, select, iframe, video, audio'));
        tabbableContent.forEach(item => {
            item.tabIndex = -1;
        });
        slide.setAttribute('aria-hidden', 'true');
    }

    enableSlide(slide) {
        // enable tabbing on active slide and show to screenreader users
        let tabbableContent = Array.prototype.slice.call(slide.querySelectorAll('a, input, button, area, object, select, iframe, video, audio'));
        tabbableContent.forEach(item => {
            item.tabIndex = 0;
        });
        slide.setAttribute('aria-hidden', 'false');
    }
}