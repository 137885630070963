


export class DomTools {



    setSelectOptionsFromRemoteSource(selectList: HTMLSelectElement, url:string, dataTextField: string, dataValueField: string, preserveOptionLabel: boolean) {

        selectList.disabled = true;

        const http = <T>(req: RequestInfo): Promise<T> =>
            fetch(req).then(res => res.json());

        http<any[]>(url).then((d:any[]) => {
            this.setSelectOptions(selectList, d, dataTextField, dataValueField, preserveOptionLabel);
            selectList.disabled = false;
        });


    }

    setSelectOptions(selectList:HTMLSelectElement, newOptions:any[], dataTextField:string, dataValueField:string, preserveOptionLabel:boolean) {
        const originalValue = selectList.value;
        let startAt = preserveOptionLabel ? 1 : 0;
        selectList.options.length = startAt;

        let originalValueExists = false;
        for (let i = 0; i < newOptions.length; i++) {
            const newOption = new Option(newOptions[i][dataTextField], newOptions[i][dataValueField]);
            selectList.options[startAt] = newOption;
            originalValueExists = newOption.value === originalValue;
            startAt++;
        }

        if (originalValueExists)
            selectList.value = originalValue;
        else
            selectList.value = selectList.options[0].value;
    }

    autoPostBack(input: HTMLInputElement) {

        input.addEventListener('change', (event) => {
            const changed = event.target as HTMLInputElement;
            const submitButton = changed.closest("form").querySelector("input[type='submit'],button[type='submit']") as HTMLInputElement;
            submitButton.click();
        });

      
    }

}