export class mobileNavigation {
    nav: HTMLElement;

    constructor(navEl: HTMLElement) {
        this.nav = navEl;

        let topLevel = Array.prototype.slice.call(this.nav.querySelectorAll('.nav-mobile-main-li0.has-children'));
        topLevel.forEach(navSection => {
            let trigger = navSection.querySelector('.nav-item-btn');
            let subNav = navSection.querySelector('.nav-mobile-main-ul1');

            trigger.addEventListener('click', e => {
                //e.preventDefault();

                let parentSection = e.target.parentNode.parentNode;
                let openSection = this.nav.querySelector('.nav-mobile-main-li0.open');

                if (openSection) {
                    if (openSection == parentSection) {
                        this.toggleSection(navSection);
                    } else {
                        this.toggleSection(openSection);
                        this.toggleSection(navSection);
                    }
                } else {
                    this.toggleSection(navSection);
                }

            });
        })
    }

    toggleSection(section) {
        let subnav = section.querySelector('.nav-mobile-main-ul1');
        let subnavBtn = section.querySelector('.nav-main-ul0 .nav-item-btn');
        if (section.classList.contains('closed')) {
            section.classList.remove('closed');
            section.classList.add('open');
            subnav.setAttribute('aria-hidden', 'false');
            subnavBtn.setAttribute('aria-hidden', 'false');
        } else {
            section.classList.remove('open');
            section.classList.add('closed');
            subnav.setAttribute('aria-hidden', 'true');
            subnavBtn.setAttribute('aria-hidden', 'true');
        }
    }
}
