import { nodeListToArray } from '../helpers/nodeListToArray';

export class NavContainerToggle {
    constructor(public htmlElement: any) {
        let menuToggle = document.querySelector('#menu-toggle'),
            menuContainer = document.querySelector('#nav-mobile'),
            searchContainer = nodeListToArray(document.querySelectorAll('[data-searchbar]')),
            searchToggle = nodeListToArray(document.querySelectorAll('[data-search-toggle]')),
            pageHeader = document.querySelector('.page-header');


        if (menuToggle) {


            menuToggle.addEventListener('click', (e) => {

                this.setHeaderBarOffset(menuContainer);

                // If mobile menu is toggled while search is open
                searchContainer.forEach(function (elem) {
                    if (elem.classList.contains('active')) {
                        elem.classList.remove('active');
                        elem.classList.remove('active');
                    }
                })
                searchToggle.forEach(function (btn) {
                    if (btn.classList.contains('active')) {
                        btn.classList.remove('active');
                        btn.classList.remove('active');
                    }
                })


                menuContainer.classList.toggle('active');
                menuToggle.classList.toggle('active');

                // keep navbar and menu open on mobile when user has toggled the menu and scrolls
                // Commenting out until page header has been created
                pageHeader.classList.toggle('mobile-menu-only-scroll');
            })

        }
    }

    setHeaderBarOffset(menuContainer) {
        const mobileNavContainer = document.getElementById('header-nav-mobile');
        const mobileInner = mobileNavContainer.querySelector('.adage-nav-inner').clientHeight;

        menuContainer.style.top = mobileInner + 'px';
       
    }



}
