import * as CONSTANTS from './constants';

export function stickyHeader() {
    window.addEventListener('load', function () {
        const header = document.getElementById('siteHeader');
        const nav = document.getElementById('mega-nav-menu');
        const main = document.getElementById('main');
        
        let headerDimensions = header.offsetHeight - nav.offsetHeight;
        let lastScrollTop = 0;

        function adjustMainContentLocation() {           
                main.style.marginTop = header.offsetHeight.toString() + 'px';
        }
        // run on page load
        adjustMainContentLocation();

        function stickyDisplayHandler() {
                var currentPos = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

                headerDimensions = header.offsetHeight - nav.offsetHeight;

                // handle header sticky
                if (currentPos === 0) {
                    if (!CONSTANTS.GLOBAL_BODY.classList.contains('locked')) {
                        header.classList.add('sticky-show');
                        header.style.top = '0px';
                        header.classList.remove('sticky-top');
                    }
                } else if (currentPos > lastScrollTop && currentPos > headerDimensions) {
                    if (!CONSTANTS.GLOBAL_BODY.classList.contains('locked')) {
                        // downscroll code
                        header.classList.add('sticky-top');
                        header.classList.remove('sticky-show');
                        header.style.top = '-' + headerDimensions + 'px';
                    }
                } else if (currentPos < lastScrollTop && currentPos > headerDimensions) {
                    if (!CONSTANTS.GLOBAL_BODY.classList.contains('locked')) {
                        // upscroll code
                        header.classList.add('sticky-show');
                        header.style.top = '0px';
                    }
                }
                // set current position to lastScrollTop
                lastScrollTop = currentPos;
        }

        window.addEventListener('resize', () =>
            window.requestAnimationFrame(() => {
                stickyDisplayHandler();
                adjustMainContentLocation();
            })
        );

        window.addEventListener('scroll', () =>
            window.requestAnimationFrame(() => {
                stickyDisplayHandler();
             
            })
        );
    });
}