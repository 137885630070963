import { nodeListToArray } from '../helpers/nodeListToArray';
import { debounce } from 'lodash';
import { enableTabbableChildren } from '../helpers/enableTabbableChildren';
import { disableTabbableChildren } from '../helpers/disableTabbableChildren';
import '../helpers/closestPolyfill';

let accordionLists = nodeListToArray(
    document.querySelectorAll('[data-accordion-list]')
);
let resizeTimer;

class AccordionsOptions {
    oneOpenAtATime: boolean = true;
}

export class Accordion {
    options: AccordionsOptions = new AccordionsOptions();

    constructor(private optArg?: any) {
        window.addEventListener(
            'resize',
            debounce(() => {
                this.setAccordionHeights.bind(this);
            }, 50)
        );
		accordionLists.forEach(accordionList => {
            accordionList.addEventListener(
                'click',
                this.accordionClick.bind(this),
                false
            );
            let panels = nodeListToArray(accordionList.querySelectorAll('[aria-hidden]'));
            panels.forEach(panel => {
                disableTabbableChildren(panel);
            })
        });
        //Check for Options
        if (this.optArg === undefined) this.optArg = new AccordionsOptions();
        else {
            this.options.oneOpenAtATime =
                this.optArg.oneOpenAtATime === undefined
                    ? this.options.oneOpenAtATime
                    : this.optArg.oneOpenAtATime;
        }
    }

    setAccordionHeights() {
        accordionLists.forEach(accordionList => {
            let openAccordions = nodeListToArray(accordionList.querySelectorAll('[aria-hidden="false"]'));
            if (openAccordions.length > 0) {
                openAccordions.forEach(openAccordion => {
                    let accordionToSize = openAccordion.querySelector(
                        '[data-accordion-content-id]'
                    );
                    let accordionNewHeight = accordionToSize.getBoundingClientRect()
                        .height;
                    openAccordion.style.height = accordionNewHeight + 'px';
                });
            }
        });
	}
	
    accordionClick(e) {
        if (e.target !== e.currentTarget) {
            let accordionToOpenId = e.target.getAttribute('aria-controls');
            if (accordionToOpenId === null) {
                accordionToOpenId = e.target.closest('[aria-controls]').getAttribute('aria-controls');
            }
            let accordionToToggle = document.querySelector('#' + accordionToOpenId);
			let accordionContentHeight = document.querySelector('[data-accordion-content-id="' + accordionToOpenId + '"]').getBoundingClientRect().height;

            if (this.options.oneOpenAtATime) {
                this.closeOpenAccordions(accordionToToggle);
			}

            if (accordionToToggle.getAttribute('aria-hidden') == 'false') {
                this.closeAccordion(accordionToToggle);
            } else {
                this.openAccordion(accordionToToggle, accordionContentHeight);
            }
            this.setAccordionHeights();
        }
        e.stopPropagation();
    }

    openAccordion(accordionToOpen, accordionToOpenHeight) {
        accordionToOpen.setAttribute('style', 'height:' + accordionToOpenHeight + 'px');
        accordionToOpen.setAttribute('aria-hidden', 'false');
        enableTabbableChildren(accordionToOpen);
        let id = accordionToOpen.id;
        document.querySelector('[aria-controls="' + id + '"]').setAttribute('aria-expanded', 'true');
    }

    closeAccordion(accordionToClose) {
        accordionToClose.setAttribute('style', 'height: 0px');
        accordionToClose.setAttribute('aria-hidden', 'true');
        disableTabbableChildren(accordionToClose);
        let id = accordionToClose.id;
        document.querySelector('[aria-controls="' + id + '"]').setAttribute('aria-expanded', 'false');
    }

	closeOpenAccordions(exception) {
		// CHECK TO SEE IF LIST IS USING UL OR DL
		let container = exception.closest('dl');

		let openAccordions = nodeListToArray(container.querySelectorAll('[aria-hidden="false"]'));

		openAccordions.forEach(openAccordion => {
			if (openAccordion !== exception) {
				this.closeAccordion(openAccordion);
			}
		});

    }
}
