
import * as cc from 'credit-card-type';

class AmtaValidationResult {
    public metAttributeValidation: boolean;
    public metRequiredValidation: boolean;
    public metDataTypeValidation: DataTypeValidationResult;
}

class DataTypeValidationResult {
    public success: boolean;
    public dataTypeMetadata: string;
}

export class AmtaValidation {

    public inputValidated: Function;

    validateDataType(validationValue: string, dataType: string): DataTypeValidationResult {

        if (!validationValue || validationValue.length === 0) {
            const noValidate = new DataTypeValidationResult();
            noValidate.success = true;
            return noValidate;
        }

        if (dataType === "email") {
            const emailRegex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,8})?$/;
            let validationResult = new DataTypeValidationResult();
            validationResult.success = emailRegex.test(validationValue);
            return validationResult;
        }
        if (dataType === "phone") {
            const phoneRegex = /^[0-9\+]{10,13}$/;
            let validationResult = new DataTypeValidationResult();
            validationResult.success = phoneRegex.test(validationValue.replace(/\s|-/g, ""));
            return validationResult;
        }
        if (dataType === "postalCode") {
            //const postalCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
            const postalCodeRegex = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/;
            let validationResult = new DataTypeValidationResult();
            validationResult.success = postalCodeRegex.test(validationValue);
            console.log(validationResult);
            return validationResult;
        }
        if (dataType === "date") {
            const d = new Date(validationValue);

            let validationResult = new DataTypeValidationResult();
            validationResult.success = !isNaN(d.getTime());
            return validationResult;
        }
        if (dataType === "number") {

            let validationResult = new DataTypeValidationResult();
            validationResult.success = !isNaN(parseFloat(validationValue));
            return validationResult;
        }
        if (dataType === "creditCard") {
            
            var matching = cc(validationValue);

            let validationResult = new DataTypeValidationResult();
            validationResult.success = matching.length > 0;
            if (matching.length === 1)
                validationResult.dataTypeMetadata = matching[0].niceType;
            return validationResult;
        }

        let validationResult = new DataTypeValidationResult();
        validationResult.success = true;
        return validationResult;
    }

    amtaTestInput(inputId:string, isRequired:boolean, dataType:string):AmtaValidationResult {
        const anyField = document.getElementById(inputId) as HTMLElement;
        if (!anyField) {
            const noField = new AmtaValidationResult();
            noField.metRequiredValidation = true;
            noField.metDataTypeValidation = new DataTypeValidationResult();
            noField.metDataTypeValidation.success = true;
            noField.metAttributeValidation = true;
            return noField;
        }
        let valueToValidate = "";
        const selectField = document.getElementById(inputId) as HTMLSelectElement;
        const inputField = document.getElementById(inputId) as HTMLInputElement;
        if (anyField.tagName === "SELECT") {
            valueToValidate = selectField.value;
        }
        if (anyField.tagName === "INPUT") {
            valueToValidate = inputField.value;
        }

        let metRequiredValidation = true;

        //////////////// handle required ////////////////
        if (isRequired) {
            if (anyField.tagName === "INPUT" && inputField.type === "radio")
                metRequiredValidation = document.querySelectorAll("[name='" + inputField.name + "']:checked").length > 0;
            else if (anyField.tagName === "INPUT" && inputField.type === "checkbox")
                metRequiredValidation = inputField.checked;
            else {

                if (valueToValidate) {
                    metRequiredValidation = true;
                } else {
                    metRequiredValidation = false;
                }
            }

        }
        /////////////////////////////////////////////////

        /////////////// attribute based /////////////////

        let metAttributeValidation = true;

        const minLength = inputField.getAttribute("data-val-minlength-min");
        if (minLength) {
            metAttributeValidation = metAttributeValidation && inputField.value.length >= parseInt(minLength);
        }

        const maxLength = inputField.getAttribute("data-val-maxlength-max");
        if (maxLength) {
            metAttributeValidation = metAttributeValidation && inputField.value.length <= parseInt(maxLength);
        }

        const minValue = inputField.getAttribute("data-val-minValue-min");
        if (minValue && inputField.value.length > 0) {

            if ("date" === dataType) {
                const val = inputField.valueAsDate;
                if (val) {
                    const compare = new Date(parseInt(minValue));
                    metAttributeValidation = metAttributeValidation && val >= compare;
                }
            }
            else
                metAttributeValidation = metAttributeValidation && parseFloat(inputField.value) >= parseFloat(minValue);
        }

        const maxValue = inputField.getAttribute("data-val-maxValue-max");
        if (maxValue && inputField.value.length > 0) {

            if ("date" === dataType) {
                const val = inputField.valueAsDate;
                if (val) {
                    
                    const compare = new Date(parseInt(maxValue));
                    metAttributeValidation = metAttributeValidation && val <= compare;
                }
            }
            else
                metAttributeValidation = metAttributeValidation && parseFloat(inputField.value) <= parseFloat(maxValue);
        }

        const compareTo = inputField.getAttribute("data-val-equalto-other");
        if (compareTo) {
            const other = compareTo.substring(2);
            const prefix = inputField.id.substring(0, inputField.id.lastIndexOf("_"));
            const otherElement = document.getElementById(other + prefix);
            const theOtherElement = document.getElementById(prefix + "_" + other);

            const compareEl1 = otherElement as HTMLInputElement;
            const compareEl2 = inputField as HTMLInputElement;
            
            if (!otherElement && theOtherElement) {
                const compareEl3 = theOtherElement as HTMLInputElement;
                metAttributeValidation = metAttributeValidation && compareEl3.value === compareEl2.value;
            } else {
                metAttributeValidation = metAttributeValidation && compareEl1.value === compareEl2.value;
            }
        }


        /////////////////////////////////////////////////

        const metDataTypeValidation = this.validateDataType(valueToValidate, dataType);

        const result = new AmtaValidationResult();
        result.metRequiredValidation = metRequiredValidation;
        result.metDataTypeValidation = metDataTypeValidation;
        result.metAttributeValidation = metAttributeValidation;
        return result;

    }

    amtaValidateInputField(inputId, dataType, required, formID, displayErrors:boolean):boolean {
        
        const isRequired = required === "required";
        const inputField = document.getElementById(inputId) as HTMLInputElement;

        const validationResult = this.amtaTestInput(inputId, isRequired, dataType);
        if (dataType === "creditCard")
            inputField.setAttribute("data-amta-validation-dataTypeMetadata", validationResult.metDataTypeValidation.dataTypeMetadata);

        const metGlobalValidation = validationResult.metAttributeValidation && validationResult.metRequiredValidation && validationResult.metDataTypeValidation.success;

        if (displayErrors) {

            if (metGlobalValidation) {
                inputField.classList.remove("amta-input-warning-default");
            } else {
                inputField.classList.add("amta-input-warning-default");
            }

            const inputRequiredMessage = document.getElementById(inputId + "_required");
            if (inputRequiredMessage) {
                if (validationResult.metRequiredValidation) {
                    inputRequiredMessage.classList.add("amta-hidden");
                } else {
                    inputRequiredMessage.classList.remove("amta-hidden");
                }
            }

            const inputInvalidMessage = document.getElementById(inputId + "_invalid");
            if (inputInvalidMessage) {
                if ((validationResult.metDataTypeValidation.success && validationResult.metAttributeValidation) || !validationResult.metRequiredValidation) {
                    inputInvalidMessage.classList.add("amta-hidden");
                } else {
                    inputInvalidMessage.classList.remove("amta-hidden");
                }
            }
        }

        const submitButton = document.getElementById(formID + "button") as HTMLButtonElement;
        if (submitButton)
            submitButton.disabled = submitButton.closest("form").querySelectorAll(".amta-red-form-validation-message:not(.amta-hidden)").length > 0;

        if (this.inputValidated) {
            this.inputValidated(inputId);
        }

        return metGlobalValidation;
    }

    raiseValidationForAllInputs(formID, fieldsForValidation,displayErrors: boolean ) {

        const validationStatus: Array<boolean> = [];

        for (var i = 0; i < fieldsForValidation.length; i++) {
            var splitString = fieldsForValidation[i].split(",");
            var inputId = splitString[0];

            var el = document.getElementById(inputId);
            const isVisible = (el.offsetWidth || el.offsetHeight || el.getClientRects().length);

            if (isVisible) {
                validationStatus.push(this.amtaValidateInputField(inputId, splitString[1], splitString[2], formID,displayErrors));
            }
        }

        var success = validationStatus.indexOf(false) === -1;

        const submitButton = document.getElementById(formID + "button") as HTMLButtonElement;
        submitButton.disabled = !success;

        return success;
    }

    beginFormSubmit(event, formID, fieldsForValidation, formAction: string): boolean {

     

        const validationResult = this.raiseValidationForAllInputs(formID,fieldsForValidation, true);

        const submitButton = document.getElementById(formID + "button") as HTMLButtonElement;
        const processingMessage = document.getElementById(formID + "processing");

        const hasPendingOperations = submitButton.closest("form").querySelectorAll("[data-role='pending-background-operation']").length > 0;
        if (hasPendingOperations)
            return false;

        if (!validationResult) {

            submitButton.classList.remove("amta-hidden");
            processingMessage.classList.add("amta-hidden");

            if (event)
                event.preventDefault();

            submitButton.closest("form").querySelectorAll(".amta-red-form-validation-message:not(.amta-hidden)")[0].scrollIntoView(false);
            return false;
        } else {


            submitButton.classList.add("amta-hidden");
            processingMessage.classList.remove("amta-hidden");

            const form = submitButton.closest("form");
            if (!formAction) {
                formAction = form.getAttribute("data-amta-form-action");
            } 
            form.setAttribute("action", formAction);

            return true;
        }
    }

}