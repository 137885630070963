
import tippy from 'tippy.js';

interface IHasAjaxState {
    _ajaxState: AjaxState;
}

class AjaxState {
    isFetching: boolean = false;
    src: string = null;
    error: string = null;
}

export class TooltipManager {
    initializeByQuerySelector(querySelector: string) {

        document.querySelectorAll(querySelector).forEach(el => {

            const ajaxSource = el.getAttribute("data-tippy-ajax-src");
            if (ajaxSource) {

                const loadingContentHtml = el.getAttribute("data-tippy-content");
               
                const ajaxConfig =
                {
                    theme: "light",
                    onCreate(instance) {

                        const ajaxStateHolder = (instance as any) as IHasAjaxState;
                        ajaxStateHolder._ajaxState = new AjaxState();
                    },
                    onShow(instance) {

                        const ajaxStateHolder = (instance as any) as IHasAjaxState;
                        const ajaxState = ajaxStateHolder._ajaxState;

                        if (ajaxState.isFetching || ajaxState.src || ajaxState.error) {
                            return;
                        }

                        ajaxState.isFetching = true;

                        fetch(ajaxSource)
                            .then(response => response.text())
                            .then(html => {
                                ajaxState.src = html;
                                ajaxState.isFetching = false;
                                instance.setContent(html);
                            })
                            .catch(error => {
                                ajaxState.error = error;
                                instance.setContent(`Unable to load shopping cart`);
                                ajaxState.isFetching = false;
                            });

                    },
                    onHidden(instance) {
                        instance.setContent(loadingContentHtml);
                        // Unset these properties so new network requests can be initiated
                        const ajaxStateHolder = (instance as any) as IHasAjaxState;
                        const ajaxState = ajaxStateHolder._ajaxState;

                        ajaxState.src = null;
                        ajaxState.error = null;
                    }
                };

                tippy(el, ajaxConfig);


            } else {
                tippy(el, { theme: "light" });
            }
        });

        
    }
}