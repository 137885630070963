export class BlockAnimation {

    private toggleClass: string = "animate";
    private toToggleElements: Array<Element> = null;
    private scrolledToClass: string = "animated-scroll";
    private loadedClass: string = "animated-load";

    constructor() {

        this.toToggleElements = [].slice.call(document.getElementsByClassName(this.toggleClass));
        if (this.toToggleElements) {
            window.addEventListener('load', this.onVisibilityChange.bind(this), false);
            window.addEventListener('scroll', this.onVisibilityChange.bind(this), false);
        }
    }

    public onVisibilityChange(event): any {
        window.requestAnimationFrame(
            () => {
                this.toToggleElements.forEach((el, i) => {
                    if (this.isElementInViewport(el)) {
                        if (event.type === "load") {
                            this.toggleClassName(el, this.loadedClass);
                            el.classList.remove(this.toggleClass);
                        }
                        if (event.type === "scroll") {
                            this.toggleClassName(el, this.scrolledToClass);

                            //Remove already toggled elements from HTMLCollection to prevent event from firing unnecessarily
                            el.classList.remove(this.toggleClass);

                            //Remove event listener when no more toggle-able elements exist
                            if (this.toToggleElements.length === 0) {
                                window.removeEventListener('scroll', this.onVisibilityChange(this.toToggleElements), false);
                            }
                        }
                    }
                });
            }
        );
    }

    // Check if element is currently in viewport; return true if true
    public isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
        return (
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom >= 0
        );
    }

    // Toggle class name
    public toggleClassName(el, className) {
        if (!el.classList.contains(className)) {
            el.className += " " + className;
        }
    }
}