import { nodeListToArray } from '../helpers/nodeListToArray';

export class SearchbarToggle {
    constructor(public htmlElement: any) {
        let searchToggles = nodeListToArray(document.querySelectorAll('[data-search-toggle]')),
            searchContainers = nodeListToArray(document.querySelectorAll('[data-searchbar]')),
            menuContainer = document.querySelector('#nav-mobile'),
            menuToggle = document.querySelector('#menu-toggle'),
            pageHeader = document.querySelector('.page-header'),
            navSearchInput = nodeListToArray(document.querySelectorAll('.nav_search')),
            navSearchIcon = nodeListToArray(document.querySelectorAll('[data-nav-search]'))

        function clearSearch() {
            navSearchInput.forEach(function (el) {
                el.value = '';
                el.focus();
                navSearchIcon.forEach(function (el) {
                    el.classList.add('hide');
                });
            })
        }

        function resultsNullCheck(text) {
            navSearchIcon.forEach(function (el) {
                if (text.value != '') {
                    el.classList.remove('hide');
                }
                else {
                    el.classList.add('hide');
                }
            });
        }

        if (searchToggles) {

            searchToggles.forEach(function (el) {
                el.addEventListener('click', (e) => {

                    if (menuContainer && menuContainer.classList.contains('active')) {
                        menuContainer.classList.remove('active');
                        menuToggle.classList.remove('active');
                        pageHeader.classList.toggle('mobile-menu-only-scroll');
                    }


                    searchContainers.forEach(function (elem) {
                        elem.classList.toggle('active');
                    })

                    pageHeader.classList.toggle('search-active');


                    searchToggles.forEach(function (btn) {
                        btn.classList.toggle('active');
                    });

                    if (document.querySelector('.nav-main-li0.has-children.open')) {
                        let openSection = document.querySelector('.nav-main-li0.has-children.open');
                        let openSectionBtn = openSection.querySelector('button');
                        let openSectionMenu = openSection.querySelector('.nav-main-ul1');
                        openSection.classList.remove('open');
                        openSection.classList.add('closed');
                        openSectionBtn.setAttribute('aria-hidden', 'true');
                        openSectionMenu.setAttribute('aria-hidden', 'true');
                    }

                    navSearchInput.forEach(function (el) {
                        el.addEventListener('input', (event) => {
                            resultsNullCheck(event.target);
                        });
                    });

                    navSearchIcon.forEach(function (el) {
                        el.addEventListener('click', (e) => {
                            e.preventDefault();
                            clearSearch();
                            navSearchInput.forEach(function (el) {
                                resultsNullCheck(el);
                            })
                        })
                    })
                })
            })

        }

    }

}
