import * as CONSTANTS from './constants';

export function displayScrollToTop() {
    let scrollToTopContainer = document.querySelector('.adage-scroll-to-top');
    const hiddenClass = 'adage-scroll-to-top-hidden';

    function displayScrollToTop(heightConstraint) {
        if (window.pageYOffset > heightConstraint && scrollToTopContainer.classList.contains(hiddenClass)) {
            scrollToTopContainer.classList.remove(hiddenClass);
        } else if (window.pageYOffset < heightConstraint && !scrollToTopContainer.classList.contains(hiddenClass)) {
            scrollToTopContainer.classList.add(hiddenClass);
        }
    }

    if (scrollToTopContainer != null) {
        window.addEventListener('scroll', function () {
            displayScrollToTop(750);
            
        });
    }
}

export function scrollToTop() {
    let scrollToTopBtn = document.querySelector('.adage-scroll-to-top');
    let mainElement = document.getElementById('main');
    let currentPos = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollToTopBtn != null) {
        scrollToTopBtn.addEventListener('click', function () {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        });
    }

    window.addEventListener('scroll', () => {
            if (window.innerHeight > mainElement.getBoundingClientRect().bottom) {
                scrollToTopBtn.classList.add('static');
                scrollToTopBtn.classList.remove('fixed');
                console.log(currentPos);
            }
            else {
                scrollToTopBtn.classList.add('fixed');
                scrollToTopBtn.classList.remove('static');
            }
    });
}