import * as A11yDialog from 'a11y-dialog';
import { nodeListToArray } from '../helpers/nodeListToArray';
import * as YouTubeIframeLoader from 'youtube-iframe';
import * as Player from "@vimeo/player/dist/player.js";
import { start } from 'repl';

export class Modal {
    modal: any;
    modalElement: HTMLElement;
    video?: any;

    constructor(modalElement: HTMLElement, container?: HTMLElement) {
        let args = container != null ? [modalElement, container] : [modalElement];
        this.modal = new A11yDialog(...args);
        this.modalElement = modalElement;
        this.modal.on('show', () => {
            document.body.style.overflow = 'hidden';
            this.initVideo();
        });
        this.modal.on('hide', () => {
            document.body.style.overflow = '';
            this.pauseVideo();
        })
    }

    initVideo() {
        let video = this.modalElement.querySelector('[data-video-type]');
        if (video) {
            let videoType = video.getAttribute('data-video-type');
            if (videoType == 'vimeo') {
                this.vimeoInit();
            }
            else if (videoType == 'youtube') {
                this.youtubeInit();
            }
            else {
                this.nativeVideoInit();
            }
           
        }
    }

    vimeoInit() {
        let vimeoIframe = this.modalElement.querySelector('iframe');
        console.log(vimeoIframe);
        if (!vimeoIframe.src) {
            vimeoIframe.src = vimeoIframe.getAttribute('data-src');
            let vimeoPlayer = new Player(vimeoIframe, {});
            this.video = vimeoPlayer;
            vimeoPlayer.ready().then(function () {
                vimeoPlayer.play();
            });
        }
       
    }

    youtubeInit() {
        let youtubeIframe = this.modalElement.querySelector('iframe');
        if (!youtubeIframe.src) {
            let uniqueID = this.modalElement.id;
            YouTubeIframeLoader.load(YT => {
                let playerID = `youtube-player-${uniqueID}`;
                youtubeIframe.id = playerID;
                youtubeIframe.src = youtubeIframe.getAttribute('data-src');
                let youtubePlayer = new YT.Player(playerID, {
                    events: {
                        'onReady': () => {
                            youtubePlayer.playVideo();
                        }
                    }
                });
                this.video = youtubePlayer;
            });
        }
        
    }

    nativeVideoInit() {
        let videoElement = this.modalElement.querySelector('video');
        if (videoElement) {
            let videoSource = videoElement.querySelector('source');
            if (!videoSource.src) {
                videoSource.src = videoSource.getAttribute('data-src');
                videoElement.load();
                videoElement.play();
            }
            this.video = videoElement;

        }
    }

    pauseVideo() {
        let videoContainer = this.modalElement.querySelector('[data-video-type]');
        if (videoContainer.getAttribute('data-video-type') == 'vimeo') {
            this.video.pause();
        }
        else if (videoContainer.getAttribute('data-video-type') == 'youtube') {
            this.video.pauseVideo();
        }
        else {
            if (!this.video.paused) {
                this.video.pause();
            }
        }
    }

}