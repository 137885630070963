import * as CONSTANTS from './modules/constants';
import { Accordion } from './modules/accordion';
import { BlockAnimation } from './modules/block-animation';
import { Gallery } from './modules/gallery';
import { FullBleedImage } from './modules/fullBleedImagesIE';
import { nodeListToArray } from './helpers/nodeListToArray';
import { SvgSprite } from './modules/svg-loader';
import { Modal } from './modules/modal';
import { mainNavigation } from './modules/main-navigation';
import { SearchbarToggle } from './modules/toggle-searchbar';
import { NavContainerToggle } from './modules/toggle-mobile-menu';
import { mobileNavigation } from './modules/mobile-navigation';
import { stickyHeader } from './modules/sticky-header';
import { displayScrollToTop } from './modules/scroll-to-top';
import { scrollToTop } from './modules/scroll-to-top';
import { AmtaValidation } from './modules/amta-validation';
import { DomTools } from './modules/dom-tools';
import { TooltipManager } from './modules/tooltips';

window["AmtaValidation"] = new AmtaValidation();
window["DomTools"] = new DomTools();

(() => {
    // SVG Sprite Inject
	let isFractalPreview = document.querySelector('[data-fractal-preview]');
	if (!isFractalPreview) {
		new SvgSprite('/Static/img/amta-sprite.svg');
	} else {
		new SvgSprite('/img/amta-sprite.svg');
	}
 
    // Block animations
    new BlockAnimation();

    // Accordion Init
    let accordions = new Accordion({
        oneOpenAtATime: true
    });

    new SearchbarToggle(CONSTANTS.SEARCH_TOGGLE);
    new NavContainerToggle(CONSTANTS.MOBILE_TOGGLE);

    //Main navigation init
    let navElement = null;
    if (CONSTANTS.DESKTOP_NAV) {
        navElement = new mainNavigation(CONSTANTS.DESKTOP_NAV as HTMLElement);
    }

    //Mobile navigation init
    let mobileNavElement = null;
    if (CONSTANTS.MOBILE_NAV) {
        mobileNavElement = new mobileNavigation(CONSTANTS.MOBILE_NAV as HTMLElement);
    }

    // Sticker Header
    stickyHeader();

    // Fix images in IE that must be full-bleed
    let fullBleedImages = nodeListToArray(document.querySelectorAll('[data-full-bleed-image] img'));
    fullBleedImages.forEach(imageEl => {
        new FullBleedImage(imageEl);
    })

    // Gallery Init
    // Note: it is recommended to use loop: false when creating a gallery with videos at the beginning/end because of duplicated slides.
    let galleryClass = 'single-item-gallery';
    let galleries = nodeListToArray(document.querySelectorAll('[data-gallery]'));
    galleries.forEach((gallery, index) => {
        gallery.classList.add(`${galleryClass}-${index}`);
        new Gallery({
            containerClass: `${galleryClass}-${index}`,
            slidesPerView: 1
        })
    });

    // Video Modals Init
    let modals = nodeListToArray(document.querySelectorAll('[data-modal]'));
    modals.forEach(modal => {
        new Modal(modal);
    })

    // Scroll to Top logic
    
    if (document.querySelector('.adage-scroll-to-top')) {
        displayScrollToTop();
        scrollToTop();
    }

    // tooltips

    const tooltipManager = new TooltipManager();
    tooltipManager.initializeByQuerySelector("[data-tippy-content]");

    // cart link text

    const cartLink = document.querySelectorAll("[data-cart-preview-link-url]");
    if (cartLink) {
        cartLink.forEach(function (currentLink) {            
            const cartLinkTextRemoteSource = currentLink.getAttribute("data-cart-preview-link-url");
            fetch(cartLinkTextRemoteSource)
                .then(response => response.text())
                .then(html => {
                    currentLink.innerHTML = html;
                });
        });
    }
})();

