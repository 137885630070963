//import isTouchDevice from './is-touch';
import { nodeListToArray } from '../helpers/nodeListToArray';
export class mainNavigation {
    fullSizeClass: string;
    shrinkClass: string;
    hideClass: string;
    visibleClass: string;
    isCollapsed: boolean;
    headerElement: HTMLElement;
    headerHeight: number;
    lastPos: number;
    curPos: number;
    delta: number;
    lastDelta: number;
    nav: HTMLElement;
    navContainer: HTMLElement;
    setForTouchscreen: Function;
    activeLink: HTMLElement;
    topNavs: Array<any>;
    body: HTMLElement;

    constructor(navigation: HTMLElement) {
        this.fullSizeClass = 'expanded';
        this.shrinkClass = 'short';
        this.hideClass = 'collapsed';
        this.visibleClass = 'visible';
        this.isCollapsed = false;

        this.headerElement = document.querySelector('section') as HTMLElement; //first section on page

        this.nav = navigation;
        this.navContainer = document.querySelector('header') as HTMLElement;
        this.activeLink = null;

        this.topNavs = Array.prototype.slice.call(this.nav.querySelectorAll('.nav-item-li0.has-children'));

        // event listeners to close meganav if user clicks outside dropdown window
        this.body = document.querySelector('body') as HTMLElement;
        this.body.addEventListener('click', (e) => {
            let targetNode = e.target as HTMLElement;
            let openSection = this.nav.querySelector('.nav-main-li0.open');
            if (openSection) {
                if (!openSection.contains(targetNode)) {
                    this.toggleSection(openSection);
                }
            }
            let searchContainers = nodeListToArray(document.querySelectorAll('[data-searchbar]'));
            let pageHeader = document.querySelector('.page-header');
            // eWeb doesn't have a search bar
            if (document.querySelector('.adage-searchbar-toggle.adage-search-btn')) {
                let searchButtons = document.querySelectorAll('[data-search-toggle]');
                searchContainers.forEach((e) => {
                    if (e.classList.contains('active') && (!searchButtons[0].parentNode.contains(targetNode) && !searchButtons[1].parentNode.contains(targetNode)) && !e.contains(targetNode)) {
                        e.classList.remove('active');
                        searchButtons[0].classList.remove('active');
                        searchButtons[1].classList.remove('active');
                        pageHeader.classList.remove('search-active');
                    }
                })
            }
        })

        //setup callback function for if the device is a touch screen - 
        //first click just focuses to drop down the nav, 2nd click goes through
        this.setForTouchscreen = () => {
            this.topNavs.forEach((link, index) => {
                let anchor = link.querySelector('a');
                anchor.addEventListener('click', e => {
                    e.preventDefault();
                    if (anchor === this.activeLink) {
                        window.location.href = anchor.href;
                    }
                    else {
                        this.activeLink = anchor;
                        link.classList.add('focused');
                    }
                })
            })
        }

        //deal with focus/keyboards     
        this.topNavs.forEach((topNav) => {
            let childTags = Array.prototype.slice.call(topNav.querySelectorAll('a'));
            childTags.forEach((childTag) => {
                childTag.addEventListener('focus', () => {
                    if (!topNav.classList.contains('focused')) {
                        topNav.classList.add('focused');
                    }
                    if (this.isCollapsed) {
                        this.navContainer.classList.remove(this.hideClass);
                        this.isCollapsed = false;
                        this.navContainer.classList.add(this.visibleClass);
                    }
                });
                childTag.addEventListener('blur', () => {
                    if (!topNav.contains(document.activeElement)) {
                        topNav.classList.remove('focused');
                    }
                });
            });
        });

        let topLevel = Array.prototype.slice.call(this.nav.querySelectorAll('.nav-main-li0.has-children'));
        topLevel.forEach(navSection => {
            let trigger = navSection.querySelector('.nav-item-btn');
            let subNav = navSection.querySelector('.nav-main-ul1');
            let searchContainers = nodeListToArray(document.querySelectorAll('[data-searchbar]'));
            let pageHeader = document.querySelector('.page-header');

            trigger.addEventListener('click', e => {
                e.preventDefault();

            searchContainers.forEach((e) => {
                if (e.classList.contains('active')) {
                    e.classList.remove('active')
                    pageHeader.classList.toggle('search-active');
                }
            })

                let parentSection = e.target.parentNode.parentNode;
                let openSection = this.nav.querySelector('.nav-main-li0.open');

                if (openSection) {
                    if (openSection == parentSection) {
                        this.toggleSection(navSection);
                    } else {
                        this.toggleSection(openSection);
                        this.toggleSection(navSection);
                    }
                } else {
                    this.toggleSection(navSection);
                }

            });
        })
    }

    toggleSection(section) {
        let subnav = section.querySelector('.nav-main-ul1');
        let subnavBtn = section.querySelector('.nav-main-ul0 .nav-item-btn');

        if (section.classList.contains('closed')) {
            section.classList.remove('closed');
            section.classList.add('open');
            subnav.setAttribute('aria-hidden', 'false');
            subnavBtn.setAttribute('aria-hidden', 'false');
        } else {
            section.classList.remove('open');
            section.classList.add('closed');
            subnav.setAttribute('aria-hidden', 'true');
            subnavBtn.setAttribute('aria-hidden', 'true');
        }
    }

    initPos() {
        this.headerHeight = Math.min((this.headerElement.getBoundingClientRect().bottom + window.pageYOffset), 500);
        this.curPos = window.pageYOffset;
        this.lastPos = this.curPos;
        this.checkPos();
        this.setSubnavHeights();
    }

    setSubnavHeights() {
        //smoother animated dropdowns
        this.topNavs.forEach(topNav => {
            let subNav = topNav.querySelector('.nav-main-ul1');
            subNav.classList.add('measuring');
            subNav.style.height = subNav.clientHeight + 'px';
            subNav.classList.remove('measuring');
        })
    }

    checkPos() {
        this.curPos = window.pageYOffset;
        this.delta = this.curPos - this.lastPos;
        this.lastPos = this.curPos;
        if (!this.isCollapsed) {
            if ((this.delta > 0 && this.lastDelta > 0 && this.curPos > 19) || document.body.classList.contains('fixed')) {
                this.navContainer.classList.add(this.hideClass);
                this.isCollapsed = true;
                this.navContainer.classList.remove(this.visibleClass);
            }
        }
        else if (((this.delta < 0 && this.lastDelta < 0) || this.curPos < 20) && !document.body.classList.contains('fixed')) {
            this.navContainer.classList.remove(this.hideClass);
            this.isCollapsed = false;
            setTimeout(() => {
                this.navContainer.classList.add(this.visibleClass);
            }, 300);
        }

        if (this.curPos <= this.headerHeight) {
            if (!this.navContainer.classList.contains(this.fullSizeClass)) {
                this.navContainer.classList.remove(this.shrinkClass);
                this.navContainer.classList.add(this.fullSizeClass);
            }
        }
        else if (this.curPos > this.headerHeight) {
            if (!this.navContainer.classList.contains(this.shrinkClass)) {
                this.navContainer.classList.remove(this.fullSizeClass);
                this.navContainer.classList.add(this.shrinkClass);
            }
        }

        this.lastDelta = this.delta;
    };
}

